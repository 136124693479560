.btn {
  font-size: 18px;
  line-height: normal;
  padding: 9px 20px 11px;
  border-radius: 22px;

  color: $light-link-secondary;
  background-color: $light-link;

  transition: background-color $hover-transition-duration ease,
              color $hover-transition-duration ease;
}

.btn svg {
  vertical-align: middle;
}

a.btn {
  text-decoration: none;
  color: $light-link-secondary;
  font-variation-settings: 'wght' 500;
  margin-right: 0;
}

.btn:hover {
  background-color: $light-link-hover;

  transition: background-color $hover-transition-duration ease,
              color $hover-transition-duration ease;
}

.btn-dark {
  background-color: $dark-link-green;
}

.btn-dark:hover {
  background-color: $dark-link-hover-green;
}

.btn.btn-secondary {
  padding: 7px 20px 9px;
  border: solid 2px $light-link-secondary-hover;
  color: $light-link-secondary-hover;
  background-color: $light-link-secondary;
}

.btn.btn-secondary-transparent {
  padding: 7px 20px 9px;
  border: solid 2px $light-link-secondary-hover;
  color: $light-link-secondary-hover;
  background-color: transparent;
}

.btn.btn-disabled {
  padding: 9px 20px 11px;
  color: white;
  background-color: gray;
  opacity: 0.7;
}

.btn.btn-secondary-transparent:hover {
  cursor: pointer;
  color: #fff;
  background-color: $light-link-hover;
  transition: background-color $hover-transition-duration ease,
  color $hover-transition-duration ease;
}

.btn-secondary:hover {
  color: $light-link-secondary;
  background-color: $light-link-secondary-hover;
}

.btn-secondary:hover .btn-secondary-icon--fill {
  fill: $light-link-secondary;
}

.btn-secondary:hover .btn-secondary-icon--stroke {
  stroke: $light-link-secondary;
}

.btn svg:last-child {
  display: inline;
  margin-left: 8px
}