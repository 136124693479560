.careers--title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.careers--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 100ms;
  position: relative;
}

.careers--button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 200ms;
  position: relative;
}

.careers--second-subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 300ms;
  position: relative;
}

.careers--accent-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 400ms;
  position: relative;
}

.team--accent-start-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.team--title-light-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 100ms;
  position: relative;
}

.team--grid-appearing {
  .team--grid-box-appearing,
  .team--grid-box-icon-appearing,
  .team--grid-box-title-appearing,
  .team--grid-box-body-appearing {
    animation-name: bottom-up-appear;
    animation-duration: 500ms;
    position: relative;
  }
  .team--grid-box-appearing-0 {

  }
  .team--grid-box-icon-appearing-0 {
    animation-delay: 100ms;
  }
  .team--grid-box-title-appearing-0 {
    animation-delay: 150ms;
  }
  .team--grid-box-body-appearing-0 {
    animation-delay: 200ms;
  }
  .team--grid-box-appearing-1 {
    animation-delay: 150ms;
  }
  .team--grid-box-icon-appearing-1 {
    animation-delay: 200ms;
  }
  .team--grid-box-title-appearing-1 {
    animation-delay: 250ms;
  }
  .team--grid-box-body-appearing-1 {
    animation-delay: 300ms;
  }
  .team--grid-box-appearing-2 {
    animation-delay: 200ms;
  }
  .team--grid-box-icon-appearing-2 {
    animation-delay: 250ms;
  }
  .team--grid-box-title-appearing-2 {
    animation-delay: 300ms;
  }
  .team--grid-box-body-appearing-2 {
    animation-delay: 350ms;
  }
  .team--grid-box-appearing-3 {
    animation-delay: 250ms;
  }
  .team--grid-box-icon-appearing-3 {
    animation-delay: 300ms;
  }
  .team--grid-box-title-appearing-3 {
    animation-delay: 350ms;
  }
  .team--grid-box-body-appearing-3 {
    animation-delay: 400ms;
  }

}

.team--accent-end-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 500ms;
  position: relative;
}

.positions--accent-start-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.positions--title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 100ms;
  position: relative;
}

.positions--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 200ms;
  position: relative;
}

.positions--content-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 300ms;
  position: relative;
}

.perks--title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.perks--img-fullscreen-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.perks--img-mobile-appearing {
  animation-name: bottom-up-appear-img;
  animation-duration: 500ms;
  position: absolute;
}

.perks--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 100ms;
  position: relative;
}

.perks--card-left-line-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
  &.perks--card-left-line-appearing-1 {
    animation-delay: 100ms;
  }
  &.perks--card-left-line-appearing-2 {
    animation-delay: 200ms;
  }
  &.perks--card-left-line-appearing-3 {
    animation-delay: 300ms;
  }
  &.perks--card-left-line-appearing-4 {
    animation-delay: 400ms;
  }
  &.perks--card-left-line-appearing-5 {
    animation-delay: 500ms;
  }
}

.perks--card-body-title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
  &.perks--card-body-title-appearing-1 {
    animation-delay: 100ms;
  }
  &.perks--card-body-title-appearing-2 {
    animation-delay: 200ms;
  }
  &.perks--card-body-title-appearing-3 {
    animation-delay: 300ms;
  }
  &.perks--card-body-title-appearing-4 {
    animation-delay: 400ms;
  }
  &.perks--card-body-title-appearing-5 {
    animation-delay: 500ms;
  }
}

.perks--card-body-subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
  &.perks--card-body-subtitle-appearing-0 {
    animation-delay: 50ms;
  }
  &.perks--card-body-subtitle-appearing-1 {
    animation-delay: 150ms;
  }
  &.perks--card-body-subtitle-appearing-2 {
    animation-delay: 250ms;
  }
  &.perks--card-body-subtitle-appearing-3 {
    animation-delay: 350ms;
  }
  &.perks--card-body-subtitle-appearing-4 {
    animation-delay: 450ms;
  }
  &.perks--card-body-subtitle-appearing-5 {
    animation-delay: 550ms;
  }
}

.contact--title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.contact--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 100ms;
  position: relative;
}

.contact--button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 200ms;
  position: relative;
}

@keyframes bottom-up-appear {
  0% {
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes bottom-up-appear-img {
  0% {
    opacity: 0;
    margin-top: 20px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes left-right-appear {
  0% {
    opacity: 0;
    left: 20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes horizontal-grow {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 3px;
  }
}