a {
  transition: color $hover-transition-duration;
  font-variation-settings: 'wght' 300;
}

a:link, a:visited, a:active {
  color: $light-link;
  text-decoration: underline;
}

a:hover {
  color: $light-link-hover;
  text-decoration: underline;
}

a.dark-link {
  color: $dark-link;
}

a.dark-link:hover {
  color: $dark-link-hover;
}

a.dark-link-green {
  color: $dark-link-green;
}

a.dark-link-green:hover {
  color: $dark-link-hover-green;
}

a.dark-link-special {
  color: $dark-special-link;
}

a.dark-link-special:hover {
  color: $dark-special-link-hover;
}

a.special-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2cc689;
  cursor: pointer;
  transition: color $hover-transition-duration;
  text-decoration: none;
  font-variation-settings: 'wght' 500;
}

a.special-link svg {
  margin-right: 8px;
}

a.special-link:hover {
  color: #37e7a1;
  transition: color $hover-transition-duration;
}

a.special-link path {
  transition: fill $hover-transition-duration;
}

a.special-link:hover path {
  fill: #37e7a1;
  transition: fill $hover-transition-duration;
}

a.special-dark-link {
  color: #97a19d;
  font-size: 17px;
  text-decoration: none;
}
a.special-dark-link:hover {
  color: #bfc5c2;
}