.hero--title {
  text-align: center;
  font-size: 38px;
  margin: 215px 0 24px;
}

.dot {
  left: -7px;
}

.hero--title-em {
  color: #2cc689
}

.hero--subtitle {
  line-height: 1.5;
  text-align: center;
  font-variation-settings: 'wght' 300;
  max-width: 680px;
  font-size: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .hero--title {
    font-size: 28px;
    margin: 110px 24px 24px;
  }

  .hero--subtitle {
    font-size: 18px;
    margin-bottom: 35px;
  }
}