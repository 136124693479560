.content {
  color: #2a2b2b;
  width: 100%;
}

.section-hero {
  padding: 0 24px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*height: 588px; */
  background-color: #181818;
  background-image: url('../../images/bg-noise.png'),
                    url('../../images/home-hero-bg-shapes.svg');
  background-repeat: repeat, no-repeat;
  background-position: center, center;
  color: #fff;
  position: static;
}

.section-careers-hero {
  height: 553px;
  background-color: #97a19d;
}

.section-about {
  padding-top: 40px;
  padding-bottom: 100px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-process {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 778px;
  padding-top: 80px;
  background-color: #181818;
  background-image: url('../../images/bg-noise.png'),
                    url('../../images/home-process-bg-shapes.svg'),
                    linear-gradient(to bottom, #21ba7e, #4fb58d 68%);
  background-repeat: repeat, no-repeat;
  background-position: top, top;
  color: #fff;
}

.section-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 110px;
  background-color: #181818;
  background-image: url('../../images/bg-noise.png'),
                    url('../../images/home-cta-bg-shapes.svg');
  background-repeat: repeat, no-repeat;
  background-position: center, center;
  color: #fff;
}

.section-join {
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-footer {
  display: flex;
  justify-content: center;
  background-color: #2a2b2b;
  color: #97a19d;
  padding-top: 50px;
  padding-bottom: 65px;
}

.about--workwith--header {
  color: #505955;
  font-variation-settings: 'wght' 300;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 40px;
  text-align: center;
}

.about--workwith {
  padding: 0 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about--workwith--logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.about--workwith--logos img {
  margin: 0 45px 24px 0;
}

.about--divider {
  height: 1px;
  background-image: linear-gradient(to right, rgba(151, 161, 157, 0), rgba(151, 161, 157, 0.5) 50%, rgba(151, 161, 157, 0));
  max-width: 1105px;
  width: 100%;
}

.about--body {
  display: flex;
  flex-direction: row;
  max-width: 1105px;
  margin-top: 122px;
}

.about--body--tagline {
  flex: 1;
  position: relative;
}

.about--body--description {
  flex: 1;
}

.about--body--tagline-text {
  position: absolute;
  left: 42px;
  max-width: 390px;
  font-size: 35px;
  line-height: 1.31;
  color: #2a2b2b;
}

.about--body--tagline--accent1 {
  width: 100%;
  height: 100%;
  background-color: #e5f4ee;
}
.about--body--tagline--accent2 {
  width: 100%;
  height: 100%;
  background-color: #2cc689;
}

.about--body--tagline--accent1-container {
  z-index: -1;
  position: absolute;
  top: -42px;
  width: 224px;
  height: 224px;
}

.about--body--tagline--accent2-container {
  z-index: -1;
  position: absolute;
  top: 210px;
  left: 42px;
  width: 73px;
  height: 19px;
}

.about--body--description--header {
  font-size: 30px;
  line-height: 1.33;
  margin-bottom: 15px;
  color: #2a2b2b;
}

.about--body--description--text {
  font-size: 20px;
  color: #2a2b2b;
  font-variation-settings: 'wght' 300;
  line-height: 1.5;
  margin-bottom: 40px;
}

.process--heading {
  max-width: 536px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 30px;
  line-height: 1.33;
  text-align: center;
}

.process--subtitle {
  font-size: 20px;
  max-width: 680px;
  margin: 15px 0 35px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-variation-settings: 'wght' 300;
  line-height: 1.5;
  text-align: center;
}

.process--work {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1105px;
  height: 495px;
  padding: 50px 50px 60px 70px;
  border-radius: 4px;
  box-shadow: 0 6px 30px -20px rgba(0, 0, 0, 0.35);
  background-color: #fff;
  color: #2a2b2b;
}

.process--realigned {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process--work--info--title img {
  margin-right: 8px;
}

.process--work--info--title {
  display: flex;
  flex-direction: row;
  color: #2cc689;
  line-height: 2;
  font-size: 20px;
}

.process--work--info--subtitle {
  margin: 15px 0;
  font-size: 30px;
  line-height: 1.33;
}

.process--work--info--text {
  margin-top: 15px;
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  line-height: 1.5;
}

.process--work--info {
  max-width: 439px;
  justify-content: space-between;
  flex-shrink: 1;
}

.process--work--spacer {
  flex-grow: 1;
  width: 110px;
}

.process--work--boxes {
  display: flex;
  flex-flow: row wrap;
  width: 435px;
  flex-shrink: 0;
}

.process--work--box {
  width: 200px;
  height: 170px;
  margin-bottom: 35px;
  margin-right: 35px;
  padding: 25px 32px 46px 30px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 22px 20px -10px rgba(241, 243, 242, 0.5);
  border: solid 1px rgba(151, 161, 157, 0.05);
  background-color: #e5f4ee;
}

.process--work--box:nth-child(2n) {
  margin-right: 0;
}

.process--work--box--icon {
  margin-bottom: 15px;
}

.process--work--box--text {
  font-size: 20px;
  line-height: normal;
}

.cta-header {
  margin-top: 150px;
  margin-bottom: 15px;
  font-size: 30px;
  line-height: 1.33;
  text-align: center;
}

.cta-header-em {
  color: #2cc689;
}

.cta-text {
  margin-bottom: 40px;
  max-width: 510px;
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}

.join--section-label img {
  margin-right: 8px;
}

.join--section-label {
  font-size: 20px;
  line-height: 2;
  display: flex;
  flex-direction: row;
  color: #2cc689;
  min-width: 150px;
  margin: 0 auto;
}

.join--header {
  margin: 10px 0 15px 0;
  font-size: 30px;
  line-height: 1.33;
  text-align: center;
}

.join--subtitle {
  max-width: 510px;
  margin-bottom: 60px;
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  line-height: 1.5;
  text-align: center;
}

.join--apply-button {
  display: flex;
  align-items: center;
}

.footer {
  /*width: 1105px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;*/
  width: 1105px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: stretch;
}

.footer--outreach {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer--outreach--summary {
  margin-right: 67px;
  font-size: 30px;
  line-height: 1.33;
  color: #fff;
}

.footer--outreach--links {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 40px;
}

.footer--outreach--links > * {
  margin-right: 50px;
}

.footer--outreach--links > *:last-child {
  margin-right: 0;
}

.footer--divider {
  height: 1px;
  margin: 40px 0;
  opacity: 0.1;
  border-radius: 5px;
  background-color: #97a19d;
}

.footer--bottom {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}

.footer--bottom--brand-info {
  display: flex;
  flex-direction: column;
}

.footer--bottom--brandinfo--tag {
  margin-top: 25px;
  margin-bottom: 40px;
  max-width: 402px;
  font-size: 18px;
  font-variation-settings: 'wght' 300;
  line-height: 1.56;

}

.footer--bottom--brandinfo--copyright {
  font-size: 17px;
  font-variation-settings: 'wght' 300;
  line-height: 1.88;
  color: #fff;
}

.footer--bottom--brandlinks {
  display: flex;
  flex-direction: row;
}

.footer--bottom--brandlinks > * {
  margin-right: 40px;
}

.footer--bottom--brandlinks > *:last-child {
  margin-right: 0;
}



@media screen and (max-width: 768px) {
  .section-hero {
    padding-bottom: 66px;
  }
  
  .section-about {
    padding: 30px 0 60px;
  }

  .about--workwith--header {
    margin-bottom: 30px;
    font-size: 17px;
  }

  .about--workwith--logos img {
    margin-right: 30px;
  }

  .about--workwith--logos {
    margin-bottom: 6px;
  }

  .about--body--description--header {
    font-size: 22px;
    line-height: 30px;
  }

  .about--body--description--text {
    font-size: 18px;
    line-height: 28px;
  }

  .section-process {
    padding-top: 40px;
    height: auto;
    background-position: 60% 0;
  }

  .process--heading {
    font-size: 22px;
    line-height: 30px;
    padding: 0 25px;
  }

  .process--subtitle {
    padding: 0 25px;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 28px;
  }

  .process--work--info {
    max-width: inherit;
  }

  .process--work {
    width: 100%;
    display: block;
    height: auto;
    padding: 50px 25px 40px;
    border-radius: inherit;
    box-shadow: inherit;
  }

  .process--work--info--title {
    font-size: 18px;
  }

  .process--realigned {
    width: 100%;
    position: relative;
  }

  .process--work--boxes {
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: url('../../images/connecting-line.png');
    background-position: 30px -6px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: inherit;
  }

  .process--work--box--icon {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .process--work--box--icon img {
    height: 24px;
    width: 24px;
  }

  .process--work--box {
    width: inherit;
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    height: inherit;
    padding: 18px 16px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 22px 20px -10px rgba(241, 243, 242, 0.5);
    border: solid 1px rgba(151, 161, 157, 0.1);
    background-color: rgba(229, 244, 238, 0);
  }

  .process--accent--end {
    position: absolute;
    bottom: -59px;
  }

  .process--work--box--text {
    font-size: 18px;
  }

  .process--work--info--subtitle {
    font-size: 22px;
  }

  .process--work--info--text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .about--body {
    display: block;
    margin-top: 60px;
  }

  .about--body--tagline--accent1-container {
    top: -22px;
    height: 127px;
    width: 157px;
  }

  .about--body--tagline--accent2-container {
    top: 154px;
    left: 10px;
    height: 13px;
    width: 50px;
  }

  .about--body--tagline {
    height: 208px;
    width: 100%;
  }

  .about--body--tagline-text {
    font-size: 28px;
    left: 10px;
    line-height: 1.29;
    max-width: 85%;
  }

  .about--body--description {
    margin: 0 10px;
  }

  .section-cta {
    padding: 80px 25px 50px;
  }

  .cta-header {
    margin-top: 0;
    font-size: 22px;
    line-height: 30px;
  }

  .cta-text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  .section-join {
    padding: 50px 25px 60px;
  }

  .join--section-label {
    font-size: 18px;
    line-height: 23px;
  }

  .join--header {
    font-size: 22px;
    line-height: 30px;
  }

  .join--subtitle {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  .section-footer {
    padding: 40px 25px 50px;
  }

  .footer--bottom {
    flex-direction: column-reverse;
    align-items: flex-start
  }

  .footer--outreach--summary {
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 10px;
  }

  .footer--outreach {
    flex-direction: column;
    align-items: center;
  }

  .footer--outreach--links {
    font-size: 18px;
    line-height: 40px;
  }

  .footer--divider {
    margin: 20px 0;
  }

  .footer--bottom--brandlinks {
    font-size: 17px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .footer--bottom--brandlinks .special-dark-link {
    margin-bottom: 15px;
  }

  .footer--bottom--brandlinks .special-dark-link:last-child {
    margin-bottom: 0;
  }

  .footer--bottom--brand-info img {
    height: 26px;
    width: 87px;
  }

  .footer--bottom--brandinfo--tag {
    font-size: 16px;
    line-height: 1.5;
    margin: 20px 0 25px;
  }

  .footer--bottom--brandinfo--copyright {
    font-size: 16px;
  }
}

@media screen and (max-width: 486px) {
  .about--body--tagline {
    height: 200px;
  }

  .about--body--tagline--accent1-container {
    top: -22px;
    height: 157px;
    width: 157px;
  }

  .about--body--tagline--accent2-container {
    top: 160px;
  }
}