.section-hero-careers,
.section-team,
.section-positions,
.section-perks,
.section-contact {
  padding: 0 25px;
}

/******* Start Section HERO *******/

.section-hero-careers {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  background: url('../../images/bg-shapes@2x.png') center center;
  background-repeat: no-repeat;
  color: #000000;
  background-color: #efefef;
  background-size: cover;
}

.careers--title {
  text-align: center;
  font-size: 38px;
  margin: 190px 0 20px;
  font-weight: 500;
  height: 68px;
}

.careers--subtitle,
.contact--subtitle {
  line-height: 1.5;
  text-align: center;
  font-variation-settings: 'wght' 300;
  max-width: 680px;
  font-size: 20px;
  margin-bottom: 40px;
  color: #2a2b2b;
}

.careers--second-subtitle {
  margin: 78px 0 22px;
  color: #97a19d;
  font-variation-settings: 'wght' 300;
  text-align: center;
  font-size: 18px;
}

.careers--title-em, .team--title-em {
  color: #2cc689
}

.careers--dashed-line-top-half {
  position: relative;
  bottom: -60px;
}

/******* End Section HERO *******/

/******* Start Section TEAM *******/

.section-team {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../images/bg-noise.png');
  background-color: #1c1c1c;
  background-repeat: repeat, no-repeat;
  background-position: center, center;
}

.team--title-light {
  text-align: center;
  font-size: 30px;
  margin-top: 35px;
  line-height: 40px;
  color: #fff;
}

.team--grid {
  margin: 62px 0 100px;
  max-width: 915px;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  justify-content: space-between;
}

.team--grid-box {
  max-width: 360px;
  height: 190px;
  img {
    margin-bottom: 23px;
  }
  margin-bottom: 65px;
}

.team--grid-box-title {
  color: #fff;
  line-height: 34px;
  font-size: 22px;
  margin-bottom: 6px;
}

.team--grid-box-body {
  color: #fff;
  line-height: 1.5;
  font-size: 18px;
  font-variation-settings: 'wght' 300;
}

/******* End Section TEAM *******/

/******* Start Section POSITIONS *******/

.section-positions {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.positions--title {
  text-align: center;
  font-size: 30px;
  margin: 35px 0 15px;
}

.positions--subtitle {
  max-width: 510px;
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  text-align: center;
  margin-bottom: 70px;
}

.positions--empty {
  background-color: rgba(241, 243, 242, 0.41);
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 100px;
  padding: 40px 100px;
  border-radius: 4px;
  word-wrap: break-word;
  &.transparent {
    background-color: transparent;
  }
}
.positions--empty-title {
  font-size: 20px;
  color: #2cc689;
  margin-bottom: 25px;
}

.positions--empty-subtitle {
  text-align: center;
  line-height: 1.5;
  font-variation-settings: 'wght' 300;
  font-size: 19px;
  margin-bottom: 30px;
  color: #2a2b2b;
}

.positions--content {
  width: 100%;
  max-width: 725px;
}

.positions--content-category {
  margin-bottom: 50px;
}

.positions--category-title {
  font-size: 22px;
  color: #2cc689;
  margin-bottom: 5px;
}

.positions--category-list-item {
  cursor: pointer;
}

.positions--category-list-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.positions--category-list-item-header-title {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 7px;
}

.positions--category-list-item-header-subtitle {
  font-size: 18px;
  margin-bottom: 10px;
  color: #97a19d;
  font-variation-settings: "wght" 300;
}

.positions--category-list-item-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-right: 18px;
}

.plus {
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.h-line {
  width: 22px;
  min-width: 22px;
  height: 2px;
  min-height: 2px;
  background-color: #2cc689;
}

.h-line-half {
  width: 10px;
  min-width: 10px;
  height: 2px;
  min-height: 2px;
  background-color: #2cc689; 
}

.v-line {
  height: 22px;
  min-height: 22px;
  width: 2px;
  min-width: 2px;
  background-color: #2cc689;
}

.positions--category-list-item-header-icon-plus {
  color: #2cc689;
  font-size: 48px;
  width: 22px;
  height: 22px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  min-width: 22px;
  min-height: 22px;
  justify-content: center;
  font-variation-settings: "wght" 200;
}

.positions--category-list-item-header-icon-vertical {
  background-color: #2cc689;
  height: 100%;
  width: 2px;
  position: relative;
  left: -50%;
}

.positions--category-list-item-body {
  margin-top: 24px;
  margin-bottom: 40px;
}

.positions--category-list-item-body-text {
  font-size: 19px;
  color: #2a2b2b;
  font-variation-settings: "wght" 300;
  line-height: 1.58;
  margin-bottom: 40px;
}

.positions--category-list-item-requirements,
.positions--category-list-item-offer {
  margin-bottom: 40px;
}

.positions--category-list-item-requirements-title,
.positions--category-list-item-offer-title {
  font-size: 19px;
  line-height: 30px;
  color: #2a2b2b;
  font-variation-settings: "wght" 500;
  margin-bottom: 15px;
}

.positions--category-list-item-requirements-item,
.positions--category-list-item-offer-item {
  display: flex;
  font-variation-settings: "wght" 300;
  align-items: flex-start;
  margin-bottom: 20px;
  img {
    margin-right: 12px;
    margin-top: 7px;
  }
}

.positions--category-list-item-requirements-item-text,
.positions--category-list-item-offer-item-text {
  font-size: 19px;
  font-variation-settings: "wght" 300;
}

.positions--category-list-item-divider {
  height: 1px;
  width: 100%;
  background-color: #97a19d;
  opacity: 0.5;
}

.positions--category-list-item-body-btn-wrapper {
  margin-right: 0;
  margin-left: auto;
  width: max-content;
}

.positions--category-list-item-body-btn {
  display: flex;
  border: 0;
  font-variation-settings: 'wght' 300;
  img {
    margin-left: 6px;
  }
}

/******* End Section POSITIONS *******/

/******* Start Section PERKS *******/

.section-perks {
  background-color: #f1f3f2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.perks--content {
  max-width: 1100px;
  margin: 40px auto 120px auto;
  width: 100%;
}

.perks--title {
  text-align: left;
  font-size: 38px;
  margin: 60px 0 15px;
}

.perks--subtitle-left-align {
  line-height: 1.5;
  text-align: left;
  font-variation-settings: 'wght' 300;
  max-width: 535px;
  font-size: 20px;
  margin-bottom: 40px;
  color: #505955;
}

.perks--header {
  width: 100%;
  max-height: 267px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.perks--img-mobile {
  display: none;
}

.perks--img-fullscreen {
  display: block;
  margin-left: 210px;
  margin-right: auto;
  width: 355px;
}

.perks--body {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex-shrink: 0;
  justify-content: space-between;
  max-width: 912px;
}

.perks--card {
  width: 100%;
  max-width: 423px;
  height: max-content;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.perks--card-left-line {
  width: 3px;
  background-color: #2cc689;
}

.perks--card-body {
  display: flex;
  flex-direction: column;
  width: max-content;
  margin-left: 20px;
  height: max-content;
}

.perks--card-body-title {
  font-size: 22px;
  height: 28px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.perks--emoji {
  height: 24px;
  width: 24px;
  margin-left: 4px;
}

.perks--card-body-subtitle {
  max-width: 400px;
  max-height: 120px;
  font-size: 19px;
  line-height: 1.58;
  color: #2a2b2b;
  font-variation-settings: 'wght' 300;
}

/******* End Section PERKS *******/

/******* Start Section CONTACT *******/

.section-contact {
  max-height: 380px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    margin-bottom: 60px;
  }
}

.contact--title {
  text-align: center;
  font-size: 30px;
  margin: 80px 0 15px;
}

/******* End Section CONTACT *******/

/******* Start Mobile Props *******/

@media screen and (max-width: 1200px) {
  .perks--img-fullscreen {
    right: 0;
  }
}

@media screen and (max-width: 896px){
  .perks--img-mobile {
    display: block;
    position: absolute;
    width: 270px;
    right: 0;
  }

  .perks--img-fullscreen {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .careers--title {
    font-size: 28px;
    margin-top: 120px;
    margin-bottom: 25px;
  }

  .careers--subtitle {
    font-size: 18px;
    margin: 0 0 35px 0;
    line-height: 1.44;
  }

  .careers--second-subtitle {
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .team--title-light {
    font-size: 22px;
    margin-top: 25px;
    line-height: 1.36;
  }

  .team--grid {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .team--grid-box {
    max-width: none;
    width: 100%;
    img {
      margin-bottom: 10px;
    }
    margin-bottom: 40px;
  }

  .team--grid-box-title {
    font-size: 20px;
  }

  .positions--title {
    font-size: 22px;
    margin-top: 30px;
  }

  .positions--subtitle {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .perks--content {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .perks--header {
    margin-bottom: 20px;
  }

  .perks--img-mobile {
    width: 180px;
  }

  .perks--title {
    font-size: 22px;
  }

  .perks--subtitle-left-align {
    font-size: 18px;
  }

  .perks--header-text {
    margin-right: 10px;
  }

  .perks--card-body {
    margin-left: 15px;
  }

  .perks--card-body-title {
    font-size: 20px;
  }

  .perks--card-body-subtitle {
    font-size: 18px;
    line-height: 1.56;
  }

  .contact--title {
    font-size: 22px;
    margin-top: 60px;
  }

  .positions--empty {
    margin: 15px auto 100px;
    padding: 40px 20px;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 655px) {
  .perks--img-mobile {
    width: 140px;
  }
}

@media screen and (max-width: 420px) {
  .perks--header {
    height: max-content;
    margin-bottom: -10px;
    max-height: none;
  }
}

@media screen and (max-width: 325px) {
  .perks--img-mobile {
    width: 100px;
  }
}