.navbar--logo-appearing {
  animation-name: header-appear;
  animation-duration: 200ms;
  position: relative;
}

.navbar--link-appearing-0 {
  animation-name: header-appear;
  animation-duration: 200ms;
  animation-delay: 50ms;
  position: relative;
}

.navbar--link-appearing-1 {
  animation-name: header-appear;
  animation-duration: 200ms;
  animation-delay: 150ms;
  position: relative;
}

.navbar--cta-appearing {
  animation-name: header-appear;
  animation-duration: 200ms;
  animation-delay: 250ms;
  position: relative;
}

.navbar--mobilecontrols-appearing {
  animation-name: header-appear;
  animation-duration: 200ms;
  animation-delay: 50ms;
  position: relative;
}

.hero--title-word {
  &.hero--title-word-appearing {
    animation-name: bottom-up-appear;
    animation-duration: 500ms;
    position: relative;
  }
  &.hero--title-word-appearing-0 {
    animation-delay: 150ms;
  }
  &.hero--title-word-appearing-1 {
    animation-delay: 250ms;
  }
  &.hero--title-word-appearing-2 {
    animation-delay: 350ms;
  }
  &.hero--title-word-appearing-3 {
    animation-delay: 450ms;
  }
  &.hero--title-word-appearing-4 {
    animation-delay: 550ms;
  }
  &.hero--title-word-appearing-5 {
    animation-delay: 650ms;
  }
}

.hero--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 450ms;
  position: relative;
}

.hero--button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 550ms;
  position: relative;
}

.about--workwith-header-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 450ms;
  position: relative;
}
.workwith--logo-appearing {
  &.workwith--logo-appearing {
    animation-name: bottom-up-appear;
    animation-duration: 500ms;
    position: relative;
  }
  &.workwith--logo-appearing-0 {
    animation-delay: 600ms;
  }
  &.workwith--logo-appearing-1 {
    animation-delay: 650ms;
  }
  &.workwith--logo-appearing-2 {
    animation-delay: 700ms;
  }
  &.workwith--logo-appearing-3 {
    animation-delay: 750ms;
  }
  &.workwith--logo-appearing-4 {
    animation-delay: 800ms;
  }
  &.workwith--logo-appearing-5 {
    animation-delay: 850ms;
  }
}

.about--body--tagline--accent1-appearing {
  animation-name: progressive-vertical-grow;
  animation-duration: 500ms;
}

.about--body--tagline--accent2-appearing {
  animation-name: progressive-horizontal-grow;
  animation-duration: 500ms;
}

.about--body--tagline-text-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
}

.about--body--description--header-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 350ms;
  position: relative;
}

.about--body--description--text-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 500ms;
  position: relative;
}

.about--body--description--button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 450ms;
  position: relative;
}

.process--heading-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.process--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 50ms;
  position: relative;
}

.process--accent-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 150ms;
  position: relative;
}

.process--realigned-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 150ms;
  position: relative;
}

.process--work--info--title-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 250ms;
  position: relative;
}

.process--work--info--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 350ms;
  position: relative;
}

.process--work--info--text-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 450ms;
  position: relative;
}

.process--work--boxes--appearing {
  .process--work--box-appearing,
  .process--work--box--icon-appearing,
  .process--work--box--text-appearing {
    animation-name: bottom-up-appear;
    animation-duration: 500ms;
    position: relative;
  }
  .process--work--box-appearing-0 {
    animation-delay: 550ms;
  }
  .process--work--box--text-appearing-0 {
    animation-delay: 650ms;
  }
  .process--work--box--icon-appearing-0 {
    animation-delay: 450ms;
  }
  .process--work--box-appearing-1 {
    animation-delay: 450ms;
  }
  .process--work--box--text-appearing-1 {
    animation-delay: 750ms;
  }
  .process--work--box--icon-appearing-1 {
    animation-delay: 800ms;
  }
  .process--work--box-appearing-2 {
    animation-delay: 800ms;
  }
  .process--work--box--text-appearing-2 {
    animation-delay: 850ms;
  }
  .process--work--box--icon-appearing-2 {
    animation-delay: 900ms;
  }
  .process--work--box-appearing-3 {
    animation-delay: 900ms;
  }
  .process--work--box--text-appearing-3 {
    animation-delay: 950ms;
  }
  .process--work--box--icon-appearing-3 {
    animation-delay: 1000ms;
  }
}

.process--accent--end-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 1000ms;
  position: relative;
}

.cta-header-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.cta-text-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 50ms;
  position: relative;
}

.cta-button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 150ms;
  position: relative;
}

.join--section-label-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  position: relative;
}

.join--header-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 50ms;
  position: relative;
}

.join--subtitle-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 150ms;
  position: relative;
}

.join--apply-button-appearing {
  animation-name: bottom-up-appear;
  animation-duration: 500ms;
  animation-delay: 250ms;
  position: relative;
}

@keyframes header-appear {
  0% {
    opacity: 0;
    top: -30px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes bottom-up-appear {
  0% {
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes progressive-horizontal-grow {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes progressive-vertical-grow {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
