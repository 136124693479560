$dark-link-green: #2cc689;
$dark-link-hover-green: #37e7a1;
$dark-link: #fff;
$dark-link-hover: rgba(255, 255, 255, 0.8);
$dark-special-link: #97a19d;
$dark-special-link-hover: #bfc5c2;

$light-link: #000;
$light-link-hover: #2a2b2b;
$light-link-secondary: #fff;
$light-link-secondary-hover: #000;

$hover-transition-duration: 0.1s
