.privacy {
  padding-top: 180px;
  padding-bottom: 50px;
  max-width: 725px;
  margin: 0 auto;
}

.privacy h1 {
  text-align: center;
  margin-bottom: 80px;
  font-size: 38px;
  line-height: 1;
}

.privacy--body h1 {
  text-align: inherit;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1;
}

.privacy--body h2 {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.5;
}

.privacy--body p {
  margin-bottom: 20px;
  font-size: 19px;
  font-variation-settings: 'wght' 300;
  line-height: 1.58;
}