.form--container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.95);
}

.form--progress-bar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  .form--progress-bar-complete {
    height: 5px;
    width: 30%;
    background-color: #2cc689;
  }
  .form--progress-bar-incomplete {
    width: 70%;
    height: 5px;
    background-color: rgba(151, 161, 157, 0.3);
  }
}

.form--content-wrapper {
  width: 100%;
  height: 80%;
  border-bottom: black;
  background-image: url('../../images/bg-noise.png');
  position: absolute;
  top: 0;
}

.form--content-close-icon-wrapper {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 50px;
  right: 50px;
  .form--content-close-icon-circle {
    width: 100%;
    height: 100%;
    border: solid 2px black;
    border-radius: 50%;
  }
  img {
    position: relative;
    left: 31%;
    top: -69%;
  }
}

.form--content {
  max-width: 535px;
  margin: 140px auto 270px auto;
}

.form--content-title {
  font-size: 30px;
  margin-bottom: 15px;
}

.form--content-subtitle {
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  margin-bottom: 50px;
}

.form--content-question {
  font-size: 22px;
  margin-bottom: 23px;
}

input {
  width: 100%;
  margin-bottom: 50px;
  height: 35px;
  font-size: 20px;
  font-variation-settings: 'wght' 300;
  background-color: transparent;
  border: none;
  border-bottom: black solid 2px;
  padding-bottom: 10px;
}

input:focus {
  outline: none;
}

::placeholder {
  color: rgba(151, 161, 157, 0.5);
}

.form--content-btn-wrapper {
  display: flex;
  align-items: center;
}

a {
  display: flex;
  width: max-content;
  img {
    margin-left: 6px;
  }
  margin-right: 15px;
}

.form--content-btn-tooltip {
  font-size: 15px;
  font-variation-settings: 'wght' 300;
  color: #97a19d;
}