$navbar--transition--duration: 0.2s;

html:not([data-scroll='0']) .navbar {
  position: fixed;
  z-index: 1000;
  top: 0px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

html:not([data-scroll='0']) .navbar--cta, .navbar--open .navbar--cta {
  background-color: #000;
  color: #fff;
}

html:not([data-scroll='0']) .navbar--cta:hover, .navbar--open .navbar--cta:hover {
  background-color: #2a2b2b;
}

html:not([data-scroll='0']) .navbar--link, .navbar--open .navbar--link {
  color: #000;
}

html:not([data-scroll='0']) .navbar--link:hover, .navbar--open .navbar--link:hover {
  color: #2a2b2b;
}

html:not([data-scroll='0']) .navbar--link_active, .navbar--open .navbar--link_active {
  border-bottom: #000 solid 1px;
}

html:not([data-scroll='0']) .navbar--link_active:hover, .navbar--open .navbar--link_active:hover {
  border-bottom: #2a2b2b solid 1px;
}

html:not([data-scroll='0']) .navbar--logo .logo--svg, .navbar--open .navbar--logo .logo--svg {
  fill: #000;
}

header {
  width: 100%
}

.navbar_dark .logo--svg {
  transition: fill $navbar--transition--duration ease;
  fill: #fff;
}

.navbar {
  padding: 0 24px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 73px;

  transition: background-color $navbar--transition--duration ease,
              box-shadow $navbar--transition--duration ease;
}

.navbar--body {
  width: 1105px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.navbar--logo {
  display: flex;
  align-items: center;
  position: static;
}

.invisible {
  opacity: 0;
}

.navbar--links {
  display: flex;
  align-items: center;
}

.navbar--link {
  color: $light-link;
  line-height: 29px;
  font-size: 18px;
  margin-right: 40px;
}

a.navbar--link {
  text-decoration: none;
  transition: color $navbar--transition--duration ease;
}

.navbar--link_active {
  font-variation-settings: 'wght' 500;
  border-bottom: #000 solid 1px;
  transition: color $navbar--transition--duration ease,
              border-bottom $navbar--transition--duration ease;
}

.navbar_dark .navbar--link {
  color: #fff;
}

.navbar_dark .navbar--link_active {
  border-bottom: #fff solid 1px;
}

.navbar--open.navbar_dark .navbar--link_active {
  border-bottom: #000 solid 1px;
}

a.navbar--link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.navbar--link_active:hover {
  border-bottom: rgba(255, 255, 255, 0.8) solid 1px;
}

.navbar--cta {
  transition: background-color $navbar--transition--duration ease,
              color $navbar--transition--duration ease;
  margin-left: 20px;
}

.navbar--mobilecontrols {
  display: none;
  height: 30px;
  width: 60px;
  position: relative;
  top: 25%;

  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#navbar--hamburger-top-line {
  width: 22px;
  height: 2px;
  background-color: #000;
  margin-bottom: 6px;
  border-radius: 2px;
}

#navbar--hamburger-bottom-line {
  width: 22px;
  height: 2px;
  background-color: #000;
  border-radius: 2px;
}



html:not([data-scroll='0']) .navbar_dark .navbar--mobilecontrols {
  #navbar--hamburger-top-line,
  #navbar--hamburger-bottom-line {
    background-color: #000;
  }
}

.navbar--open {
  background-color: #fff;
}

.navbar--divider {
  display: none;
}

a.light-link:hover {
  color: $light-link-hover;
}

.navbar_light a.navbar--link_active:hover {
  color: $light-link-hover;
  border-bottom: #2a2b2b solid 1px;
}

.opening #navbar--hamburger-top-line {
  animation-name: line1--navbar_menu_spin_opening;
  animation-duration: 0.2s;
  transition: ease-in;
}

.closing #navbar--hamburger-top-line {
  animation-name: line1--navbar_menu_spin_opening;
  animation-duration: 0.2s;
  animation-direction: reverse;
  transition: ease-in;
}

.opening #navbar--hamburger-bottom-line {
  animation-name: line2--navbar_menu_spin_opening;
  animation-duration: 0.2s;
  transition: ease-in;
}

.closing #navbar--hamburger-bottom-line {
  animation-name: line2--navbar_menu_spin_opening;
  animation-duration: 0.2s;
  animation-direction: reverse;
  transition: ease-in;
}

@keyframes fade_in_show {
  0% {
       opacity: 0;
  }

  100% {
       opacity: 1;
  }
}

@keyframes line1--navbar_menu_spin_opening {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(45deg) translate(3px, 3px);
  }
}

@keyframes line2--navbar_menu_spin_opening {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg) translate(3px, -3px);
  }
}

@media screen and (max-width: 768px) {
  .navbar--mobilecontrols {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .navbar_dark .navbar--mobilecontrols #navbar--hamburger-top-line {
    background-color: #fff;
    transition: fill $navbar--transition--duration ease;
  }

  .navbar_dark .navbar--mobilecontrols #navbar--hamburger-bottom-line {
    background-color: #fff;
    transition: fill $navbar--transition--duration ease;
  }

  .navbar--open .navbar--mobilecontrols {
    #navbar--hamburger-top-line {
      background-color: black;
      &.open {
        transform: rotate(45deg) translate(3px, 3px);
      }
    }
    #navbar--hamburger-bottom-line {
      background-color: black;
      &.open {
        transform: rotate(-45deg) translate(3px, -3px);
      }
    }
  }

  .navbar--links {
    z-index: 1;
    display: none;
    animation: fade_in_show $navbar--transition--duration;
  }

  .navbar--open .navbar--divider {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    border-radius: 1px;
    background-color: rgba(151, 161, 157, 0.3);
  }

  .navbar--open .navbar--links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    padding: 0 25px;
    top: 60px;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    animation: fade_in_show $navbar--transition--duration;
  }

  .navbar--open .navbar--link {
    margin-bottom: 30px;
    font-size: 22px;
    color: #000;
  }

  .navbar--open .navbar--cta {
    margin-left: 0;
  }

  .navbar--open .navbar--cta:hover {
    background-color: $light-link-hover;
  }

  .navbar--logo svg {
    height: 26px;
    width: 110px;
  }

  .navbar {
    height: 60px;
  }
}

/*@media screen and (max-width: 800px) {
  .logo--svg_dark {
    fill: #000;
  }

  .navbar {
    background-color: #fff;
  }

  .navbar a.dark-link {
    color: #000;
  }

  .navbar--link_active {
    border-bottom: #000 solid 1px;
  }
}
*/